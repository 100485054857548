<template>
    <main>
      <div class="content-section">
        <div class="container-fluid"  v-html="DachziegelData.data.options.settings.app_impressum">

        </div>
      </div>
      <div class="divider">
        <div class="container-fluid">
          <hr class="divider">
        </div>
      </div>
    </main>
</template>
<script>
    import {DachziegelDataMixin} from "../mixins/DachziegelDataMixin";
    export default {
        name: 'contentImpressum',
        mixins: [DachziegelDataMixin],
        mounted() {
            this.$DKFuctions.headerShowGoBackHide()
        },
    }
</script>
